<template>
    <div class="form-box">
        <el-form :model="form">
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-form-item label="商户名称:">
                        <el-input v-model="form.merchant_name" placeholder="" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="商户号:">
                        <el-input v-model="form.merchant_no" placeholder="" />
                    </el-form-item>
                </el-col>
                <el-col :span="9">
                    <el-form-item label="创建时间:">
                        <el-date-picker v-model="form.datetime" type="datetimerange" range-separator="至"
                            value-format="YYYY-MM-DD HH:mm:ss" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-button type="primary" id="search" @click="search">
                            <el-icon>
                                <Search />
                            </el-icon>
                            搜索
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table-box">
        <div class="table-head">
            <div class="panel">
                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>交易笔数</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <p>{{ statistics.pay_number && statistics.pay_number > 0 ? statistics.pay_number
                                    : 0 }} </p>
                                <p>交易笔数</p>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>交易金额</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <p>{{ statistics.total_amount && statistics.total_amount > 0 ? statistics.total_amount : 0
                                }} </p>
                                <p>交易金额</p>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>退款金额</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <p>{{ statistics.refund_amount && statistics.refund_amount > 0 ? statistics.refund_amount :
                                    0
                                }} </p>
                                <p>退款金额</p>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>手续费</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <p>{{ statistics.fee_amount && statistics.fee_amount > 0 ? statistics.fee_amount : 0 }} </p>
                                <p>手续费</p>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>结算金额</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <p>{{ statistics.settlement_amount && statistics.settlement_amount > 0 ?
                                    statistics.settlement_amount : 0 }} </p>
                                <p>结算金额</p>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="table-main">
            <c-table ref="orderTable" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="false">
                <el-table-column label="商户号" prop="merchant_no" />
                <el-table-column label="渠道商" prop="first_agent_name" />
                <el-table-column label="合作商" prop="second_agent_name" />
                <el-table-column label="统计日期">
                    <template #default="scope">
                        {{ timeFormat(scope.row.created_at) }}
                    </template>
                </el-table-column>
                <el-table-column label="支付笔数" prop="pay_number" />
                <el-table-column label="交易总额" prop="total_amount" />
                <el-table-column label="退款金额" prop="refund_amount" />
                <el-table-column label="手续费" prop="fee_amount" />
                <el-table-column label="结算金额" prop="settlement_amount" />
            </c-table>
        </div>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, nextTick } from 'vue'
import { getTodayStartAndEnd, timeFormat } from '@/func/time'
import { Search } from '@element-plus/icons-vue'
import cTable from '@/components/CustomTable'
const { proxy } = getCurrentInstance();

const orderTable = ref(null)

const tableDataUrl = proxy.$api.order.statistics.merchantList;

let form = ref({
    datetime: getTodayStartAndEnd()
})

onMounted(() => {
    checkTime()
    queryOrderCollect()
    nextTick(() => {
        orderTable.value.setCustomLoad(true)
        orderTable.value.search(form)
    })
})

const checkTime = () => {
    if (form.value.datetime.length == 2) {
        form.value.time_start = form.value.datetime[0]
        form.value.time_end = form.value.datetime[1]
    }
}

const search = () => {
    checkTime()
    queryOrderCollect()
    nextTick(() => {
        orderTable.value.search(form)
    })
}

const statistics = ref({})
const queryOrderCollect = () => {
    proxy.$api.order.statistics.merchantListCollect(form.value).then(r => {
        statistics.value = r.data
    })
}
</script>